import tinycolor from "tinycolor2";

export default {
    methods: {
        formatColorToRgb(input, alpha) {
            var color = tinycolor(input);
            color.setAlpha(alpha);
            return color.toRgbString();
        },
        shadow(color, hideColor) {
            let alpha = 0.3;
            if (!color || hideColor) {
                color = "#000000";
                alpha = 0.05;
            }
            let shadowColor = "0 1rem 1rem -0.9rem ";
            shadowColor += this.formatColorToRgb(color, alpha);
            return shadowColor + " !important";
        },
    }
};