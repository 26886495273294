<template>
  <svg
    viewBox="121.822 107.837 231.311 186.298"
    :width="width"
    :height="height"
  >
    <path
      d="M 307.967 223.531 L 308.939 222.559 L 329.338 202.161 L 276.075 148.898 L 255.676 169.297 L 237.441 151.077 L 218.783 169.736 L 198.384 149.337 L 145.482 202.239 L 237.363 294.135 L 307.967 223.531 Z"
      :fill="color"
    />
    <path
      d="M 219.441 171.303 L 238.1 152.644 L 193.398 107.927 L 121.822 179.503 L 146.141 203.806 L 199.043 150.904 L 219.441 171.303 Z"
      :fill="accent"
    />
    <path
      d="M 255.514 171.213 L 236.856 152.555 L 281.557 107.837 L 353.133 179.413 L 328.815 203.716 L 275.913 150.814 L 255.514 171.213 Z"
      fill-opacity="0.65"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  props: ["width", "height", "accent", "color"],
};
</script>

<style scoped></style>
