<template>
  <div>
    <v-app-bar
      flat
      :fixed="absolute"
      :class="{ dark: dark }"
      :dark="dark"
      :style="{
        boxShadow: shadow('#000000', 0.03),
      }"
    >
      <div class="d-none d-md-block">
        <v-btn icon to="/">
          <IconLogo
            :height="25"
            :width="25"
            :color="dark ? '#ffffff' : '#21118D'"
            :accent="dark ? '#97D7D8' : '#97D7D8'"
          />
        </v-btn>
      </div>

      <v-app-bar-nav-icon
        class="d-block d-md-none"
        @click.stop="showDrawer = !showDrawer"
        :dark="dark"
      ></v-app-bar-nav-icon>

      <v-spacer></v-spacer>

      <div class="d-none d-md-block">
        <v-btn to="/patient" text rounded class="mr-1">
          <span>Patient</span></v-btn
        >

        <v-btn to="/consultant" text rounded class="mr-1">
          <span>Consultant</span></v-btn
        >

        <v-btn to="/hospital" text rounded class="mr-1">
          <span>Hospital</span></v-btn
        >

        <v-btn
          text
          rounded
          href="https://calendly.com/patrec-daniel/patrec-demo"
          target="_blank"
        >
          <span>Book a demo</span></v-btn
        >
      </div>

      <div class="d-block d-md-none">
        <v-btn icon to="/">
          <IconLogo
            :height="25"
            :width="25"
            :color="dark ? '#ffffff' : '#21118D'"
            :accent="dark ? '#97D7D8' : '#97D7D8'"
          />
        </v-btn>
      </div>

      <v-spacer> </v-spacer>

      <v-tooltip>
        <template v-slot:activator="{ props }">
          <v-btn
            class="mr-1"
            icon
            to="/contact"
            v-bind="props"
            :color="!dark ? 'black' : 'white'"
          >
            <v-icon>mdi-email</v-icon></v-btn
          >
        </template>
        <span>Contact</span>
      </v-tooltip>
    </v-app-bar>

    <v-navigation-drawer v-model="showDrawer" absolute temporary left>
      <v-list>
        <v-list-item to="/">
          <v-list-item-title>Home</v-list-item-title>
          <v-list-item-icon>
            <v-icon>mdi-chevron-right</v-icon>
          </v-list-item-icon>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item to="/patient">
          <v-list-item-title>Patient</v-list-item-title>
          <v-list-item-icon>
            <v-icon>mdi-chevron-right</v-icon>
          </v-list-item-icon>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item to="/consultant">
          <v-list-item-title>Consultant</v-list-item-title>
          <v-list-item-icon>
            <v-icon>mdi-chevron-right</v-icon>
          </v-list-item-icon>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item to="/hospital">
          <v-list-item-title>Hospital</v-list-item-title>
          <v-list-item-icon>
            <v-icon>mdi-chevron-right</v-icon>
          </v-list-item-icon>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item
          href="https://calendly.com/patrec-daniel/patrec-demo"
          target="_blank"
        >
          <v-list-item-title>Book a demo</v-list-item-title>
          <v-list-item-icon>
            <v-icon>mdi-chevron-right</v-icon>
          </v-list-item-icon>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item to="/contact">
          <v-list-item-title>Contact</v-list-item-title>
          <v-list-item-icon>
            <v-icon>mdi-chevron-right</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import Color from "@/mixins/color";

import IconLogo from "@/components/shared/IconLogo";

export default {
  components: { IconLogo },
  props: ["absolute", "dark"],
  mixins: [Color],
  data() {
    return {
      showDrawer: false,
    };
  },
};
</script>

<style scoped>
.dark {
  background: rgba(0, 0, 0, 0.9) !important;
}

@supports (
  (-webkit-backdrop-filter: blur(2em)) or (backdrop-filter: blur(2em))
) {
  .dark {
    background: rgba(0, 0, 0, 0.5) !important;
    backdrop-filter: saturate(200%) blur(2em);
    -webkit-backdrop-filter: saturate(200%) blur(2em);
  }
}
</style>
